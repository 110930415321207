@font-face {
    font-family: 'latoCyr';
    src: url(./fonts/Lato-Regular.woff2);
}

@font-face {
    font-family: 'latoBlackCyr';
    src: url(./fonts/Lato-Black.woff2);
}

@font-face {
    font-family: 'latoHeavyCyr';
    src: url(./fonts/Lato-Heavy.woff2);
}

body > #root {
    font-family: 'latoCyr', sans-serif;
}

@media only screen and (min-width: 992px) {
    .navbar {
        max-height: 45px;
    }
}

.nav-right .dropdown-menu {
    left: -165px !important;
}

.fp-section {
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.fp-watermark {
    display: none;
}

.fp-overflow {
    height: 100vh;
}

.services:nth-of-type(2) {
    background: url('./images/1.jpg') no-repeat center/cover;
}
.services:nth-of-type(3) {
    background: url('./images/3.jpg') no-repeat center/cover;
}
.services:nth-of-type(4) {
    background: url('./images/2.jpg') no-repeat center/cover;
}
.services:nth-of-type(5) {
    background: url('./images/4.jpg') no-repeat center/cover;
}

@media only screen and (max-width: 768px) {
    .services:nth-of-type(2) {
        background-position: 37.5% 0;
    }
    .services:nth-of-type(3) {
        background: url('./images/3m.jpg') no-repeat center/cover;
        background-position: 68% 0;
    }
    .services:nth-of-type(4) {
        background: url('./images/2m.jpg') no-repeat center/cover;
        background-position: 78% 0;
    }
}

#fp-nav ul li a span {
    background: #fff !important;
    box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.05);
}
